import {
    page,
} from 'reaxl-analytics-handlers';

export default {
    page: (metadata, option) => {
        const { data } = metadata;
        return page({
            ...metadata,
            data: {
                ...data,
                pageType: 'error',
                pageName: 'error',
            },
        }, option);
    },
};
