import React from 'react';

import { AnalyticsEngine } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { ErrorPage } from 'reaxl-organisms';

import errorPageAnalyticsHandlers from '@/analytics/errorPageAnalyticsHandlers';

import { CommonHeadContainer } from '@/containers';

import ErrorPageEventsContainer from '@/containers/ErrorPageEventsContainer';

/**
 * `Error` component used for handling errors.
 */
function Error({ statusCode }) {
    const {
        brand: [, { error_page_links: errorPageLinks }],
        thirdparty: [enableThirdParty],
        google_analytics: [, { disableGtm, delay }],
    } = useFeatures([
        'brand',
        'thirdparty',
        'google_analytics',
    ]);

    const analyticsProps = {
        getDataIsland: () => ({
            page: {
                BIRF: {
                    pg: 'error',
                    pg_id: statusCode ? `error${statusCode}` : 'error',
                },
            },
        }),
        google: {
            delay,
        },
        value: {
            ...errorPageAnalyticsHandlers,
        },
        option: {
            isOptimizelyEdge: false,
            disableGTM: disableGtm || !enableThirdParty,
            disableGTMEvents: false,
            disablePixall: !enableThirdParty,
        },
    };

    return (
        <>
            <AnalyticsEngine {...analyticsProps} />
            <CommonHeadContainer
                pageName="errorPage"
            />
            <ErrorPage
                statusCode={statusCode}
                links={errorPageLinks}
            />
            <ErrorPageEventsContainer statusCode={statusCode} />
        </>
    );
}

Error.getInitialProps = ({ res = {}, err = {} }) => {
    let { statusCode } = res;

    // if there is not a statusCode in response
    // extract a statusCode from err or default as a 404
    if (!statusCode) {
        ({ statusCode = 404 } = err);
    }

    // We don't want error pages returning as ok
    // replace a 200 statusCode with a 500
    if (statusCode === 200) {
        statusCode = 500;
    }

    return { statusCode };
};

Error.displayName = 'ErrorPage';

export default Error;
