import { getConsumerMetadata, sendPage } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';

export default function useSendErrorPageEvent(statusCode) {

    const { brand } = useBrand();

    return async ({
        isInitialLoad = false,
        event,
    } = {}) => {
        try {
            let consumerMetadata = {};

            if (brand !== 'ford') {
                consumerMetadata = await getConsumerMetadata();
            }

            sendPage({
                data: {
                    consumerMetadata,
                    statusCode,
                },
                isInitialLoad,
                event,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to send Page Event', error);
        }
    };
}
