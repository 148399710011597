import { useRef } from 'react';

import { usePageEvent } from '@bonnet/next/use-page-event';

import useSendErrorPageEvent from '@/analytics/useSendErrorPageEvent';

function ErrorPageEventsContainer({ statusCode }) {
    const { onPageEnter } = usePageEvent('errorPage', { priority: 1 });
    const sendErrorPageEvent = useSendErrorPageEvent();
    const isErrorPageFired = useRef(false);

    onPageEnter(async (err, url) => {
        if (window && window.newrelic && !isErrorPageFired.current) {
            window.newrelic.addPageAction('ErrorPage', { statusCode });
            isErrorPageFired.current = true;
        }
        await sendErrorPageEvent(err, url);
    });

    return null;
}

export default ErrorPageEventsContainer;
